<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        :width="800"
        :height="500"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;height: inherit;">
                <!-- <div class="v-modal-header">
                    <div></div>
                    <div>
                        <span class="v-modal-dialog-title v-modal-title">{{edit_create}} AN EVENT</span>
                    </div>
                    <div>
                        <span class="icon icon-close mr-2 text-white pointer close-left" @click="hideCreateEvent"></span>
                    </div>
                </div> -->
                <div class="py-6 px-4">
                    <div class="v-modal-layout">
                        <div>
                            <div class="infoHeader new-add-edit-address-card pb-4 content-left">
                                <div class="d-flex justify-content-between align-items-center">
                                <span class="new-blue fs-14" v-if="add_edit_mode == 'add'" style="font-weight: 550;">Add New Address</span>
                                <span class="new-blue fs-14" v-else style="font-weight: 550;">Edit Address</span>
                                <!-- <div class="d-flex align-items-center">
                                    <el-dropdown trigger="click" @visible-change="dropdownsData($event, 'address')" :hide-on-click="true" style="width: auto">
                                    <span class="el-dropdown-link">
                                        Address Type <i class="el-icon-arrow-down el-icon--right new-blue" style="font-weight: 500; font-size: 20px;"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" style="left: auto !important; right: 0 !important;  width: 200px !important;">
                                        <el-dropdown-item v-if="dropdown_loading" >
                                            <div class="w-100" v-loading="dropdown_loading"></div>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-else-if="!dropdown_loading && addressTypes.length == 0">Address types not found.</el-dropdown-item>
                                        <el-dropdown-item v-else v-for="item in addressTypes" :key="item" @click.native="add_edit_address.address_type = item">{{item}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                    </el-dropdown>
                                </div> -->
                                </div>
                                <!-- <div class="mt-3" v-if="index != 0 && $route.params.id == loggedInUser.user_id">
                                <div class="mt-3 mb-3 ml-4">
                                    <label class="custom-control custom-checkbox digi-checkbox">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        @input="duplicatePermanentAddress($event, index)"
                                        :checked="boolDuplicateAddress.includes(index)"
                                    />
                                    <span class="custom-control-indicator"></span>
                                    <span class="form-check-description fw-600 pt-2">Same as Permanent Address</span>
                                    </label>
                                </div>
                                </div> -->
                                <div class="">
                                <div class="content-left">
                                    <div v-if="$route.params.id == loggedInUser.user_id" class="d-flex justify-content-between align-items-center mt-1">
                                    <div class="form-group gMap" style="position:relative;width:100%;">
                                        <gmap-autocomplete
                                        class="inputFieldNoBorder mt-0"
                                        id="autocompleteInput"
                                        name="get_location"
                                        placeholder="Search Location"
                                        @place_changed="setPlace($event)"
                                        :value="add_edit_address.location_vmodel"
                                        v-model="add_edit_address.location_vmodel"
                                        style="padding: 8px 32px 8px 18px !important; height: 40px;"
                                        ></gmap-autocomplete>
                                        <img
                                        src="/static/images/marker_pin_new.svg"
                                        style="position: absolute;right: 12px;bottom: 10px;"
                                        />
                                    </div>
                                    <div class="form-group gMap ml-4" style="cursor: pointer;" @click="getLocation()">
                                        <img src="/static/images/gps_new.svg" style="height: 40px;" />
                                    </div>
                                    </div>

                                    <div class="row px-2">
                                        <div class="col-lg-12 col-md-12 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">Address Type</div>
                                            <div class="w-100" style="position: relative;">
                                                <div class="d-flex align-items-center w-100">
                                                    <input
                                                        class="input-field-underline-address"
                                                        type="text"
                                                        name="street"
                                                        :maxlength="30"
                                                        v-model="add_edit_address.address_type"
                                                        style="padding-left: 10px !important;"
                                                        placeholder="Enter Address Type"
                                                        oninput="this = this.toUpperCase()"
                                                    />
                                                    <!-- <div v-if="edit_street == true && edit_street_index == index" class="d-flex justify-content-end align-items-center">
                                                        <div class="pointer" @click="resetUser('street',index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addAddressMethod('street', index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="text-right text-grey position-absolute" style="right: 0;"><span>{{(add_edit_address.address_type).length}}/30</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                        <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">Country<span class="text-danger">*</span></div>
                                        <div class="w-100" style="position: relative;">
                                            <div class="d-flex align-content-center w-100" style="position: relative;">
                                                <multiselect
                                                    :max-height="200"
                                                    name="country"
                                                    class="diginew-multiselect diginew-multiselect-underline-address text-dark"
                                                    v-model="add_edit_address.country"
                                                    @input="add_edit_address.state = '', add_edit_address.city = '',add_edit_address.street = '',add_edit_address.township = '',add_edit_address.zip_code = '',add_edit_address.location_vmodel = ''"
                                                    :options="country_options"
                                                    @close="countryClose(add_edit_address.country)"
                                                    :disabled="$route.params.id != loggedInUser.user_id"
                                                    :searchable="true"
                                                    :close-on-select="true"
                                                    v-validate="'required'"
                                                    :show-labels="false"
                                                    placeholder="Select Country" style="padding-bottom: 5px !important;padding-top: 7px !important;">
                                                    <template slot="noOptions">
                                                        <div class="text-secondary text-center fw-600">List is Empty</div>
                                                    </template>
                                                    <template slot="noResult">
                                                        <div class="text-secondary text-center fw-600">No Results Found</div>
                                                    </template>
                                                </multiselect>
                                                <!-- <div v-if="edit_country == true && edit_country_index == index" class="d-flex justify-content-end align-items-center">
                                                    <div class="pointer" @click="resetUser('country',index)">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="addAddressMethod('country', index)">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div> -->
                                                <div style="position: absolute; bottom: -18px;">
                                                    <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-if="errors.has('country')">{{errors.first('country')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">State<span class="text-danger">*</span></div>
                                            <div class="w-100" style="position: relative;">
                                                <div class="d-flex align-content-center w-100">
                                                    <multiselect
                                                        :max-height="200"
                                                        name="state"
                                                        class="diginew-multiselect diginew-multiselect-underline-address text-dark"
                                                        v-model="add_edit_address.state"
                                                        @open="updateState(add_edit_address.country)"
                                                        :options="states"
                                                        :disabled="add_edit_address.country == '' || add_edit_address.country == null || add_edit_address.country == undefined"
                                                        @close="stateClose(add_edit_address.state)"
                                                        :close-on-select="true"
                                                        :show-labels="false"
                                                        :loading="isState"
                                                        v-validate="'required'"
                                                        :placeholder="add_edit_address.country ? 'Select State' : 'Select Country first'" style="padding-bottom: 5px !important;padding-top: 7px !important;">
                                                   
                                                    <!-- <multiselect
                                                        :max-height="200"
                                                        name="state"
                                                        class="diginew-multiselect diginew-multiselect-underline-address mt-1 text-dark"
                                                        v-model="add_edit_address.state"
                                                        @open="updateState(add_edit_address.country)"
                                                        :options="states"
                                                        :disabled="add_edit_address.country == '' || add_edit_address.country == null || add_edit_address.country == undefined"
                                                        @close="stateClose(add_edit_address.state)"
                                                        :searchable="true"
                                                        v-validate="'required'"
                                                        :close-on-select="true"
                                                        :show-labels="false"
                                                        :loading="isState"
                                                        :placeholder="add_edit_address.country ? 'Select State' : 'Select Country first'"
                                                    > -->
                                                        <template slot="noOptions">
                                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                                        </template>
                                                        <template slot="noResult">
                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                        </template>
                                                    </multiselect>
                                                    <div style="position: absolute; bottom: -18px;">
                                                        <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-if="errors.has('state')">{{errors.first('state')}}</span>
                                                    </div>
                                                    <!-- <div v-if="edit_state == true && edit_state_index == index" class="d-flex justify-content-end align-items-center">
                                                        <div class="pointer" @click="resetUser('state',index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addAddressMethod('state', index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">City</div>
                                            <div class="w-100" style="position: relative;">
                                                <div class="d-flex align-items-center w-100">
                                                    <input
                                                        class="input-field-underline-address"
                                                        type="text"
                                                        name="city"
                                                        style="padding-left: 10px !important;"
                                                        :maxlength="maxlengthaccounttownship"
                                                        v-model="add_edit_address.city"
                                                        placeholder="Enter City"
                                                        oninput="this = this.toUpperCase()"
                                                    />
                                                    <!-- <div v-if="edit_city == true && edit_city_index == index" class="d-flex justify-content-end align-items-center">
                                                        <div class="pointer" @click="resetUser('city',index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addAddressMethod('city', index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="text-right text-grey position-absolute" style="right: 0;"><span>{{(add_edit_address.city).length}}/30</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">Township</div>
                                            <div class="w-100" style="position: relative;">
                                                <div class="d-flex justify-content-end align-items-center">
                                                    <div class="d-flex align-items-center w-100">
                                                        <input
                                                            class="input-field-underline-address"
                                                            type="text"
                                                            name="township"
                                                            style="padding-left: 10px !important;"
                                                            :maxlength="maxlengthaccounttownship"
                                                            v-model="add_edit_address.township"
                                                            placeholder="Enter Township"
                                                            oninput="this = this.toUpperCase()"
                                                        />
                                                        <!-- <div v-if="edit_township == true && edit_township_index == index" class="d-flex justify-content-end align-items-center">
                                                            <div class="pointer" @click="resetUser('township',index)">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="addAddressMethod('township', index)">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="text-right text-grey position-absolute" style="right: 0;"><span>{{(add_edit_address.township).length}}/30</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">Street</div>
                                            <div class="w-100" style="position: relative;">
                                                <div class="d-flex align-items-center w-100">
                                                    <input
                                                        class="input-field-underline-address"
                                                        type="text"
                                                        name="street"
                                                        :maxlength="maxlengthaccountstreet"
                                                        v-model="add_edit_address.street"
                                                        style="padding-left: 10px !important;"
                                                        placeholder="Enter Street"
                                                        oninput="this = this.toUpperCase()"
                                                    />
                                                    <!-- <div v-if="edit_street == true && edit_street_index == index" class="d-flex justify-content-end align-items-center">
                                                        <div class="pointer" @click="resetUser('street',index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addAddressMethod('street', index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="text-right text-grey position-absolute" style="right: 0;"><span>{{(add_edit_address.street).length}}/30</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-2 pb-4">
                                        <div class="d-flex align-items-center new-contactView ">
                                            <div class="fs-14 fw-600 text-secondary profile-content-left-text-address">Zip Code</div>
                                            <div class="w-100" style="position: relative;">
                                                <div class="d-flex align-items-center w-100">
                                                    <input
                                                        class="input-field-underline-address"
                                                        type="text"
                                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                        name="pin_code"
                                                        :maxlength="maxlengthaccountzipcode"
                                                        style="padding-left: 10px !important;"
                                                        v-model="add_edit_address.zip_code"
                                                        placeholder="Enter Zip Code"
                                                    />
                                                    <!-- <div v-if="edit_zip_code == true && edit_zip_code_index == index" class="d-flex justify-content-end align-items-center">
                                                        <div class="pointer" @click="resetUser('zip_code',index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addAddressMethod('zip_code', index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="text-right text-grey position-absolute" style="right: 0;"><span>{{(add_edit_address.zip_code).length}}/30</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions">
                    <div class="d-flex justify-content-center align-items-center mt-1">
                        <button class="btn btn-outline-danger mr-3" @click="hideAddEditAddress" :disabled="save_api_call_in_progress" style="border-radius: 5px !important; min-width: 120px;">Cancel</button>
                        <button class="btn btn-new-success ml-3" @click="saveAddress" :disabled="save_api_call_in_progress" style="min-width: 120px;" >Save
                            <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import axios from "axios";
import globals from '../globals';
import profile from '../mixins/profile';
import countries from "../json/countries.json";
import { User } from "../mixins/user";
import { SweetModal } from 'sweet-modal-vue';
export default {
    data() {
        return {
            warning_msg: '',
            success_msg: '',
            add_edit_address: {
                street: "",
                city: "",
                township: "",
                state: "",
                country: "",
                address_type: "Current Address",
                zip_code: "",
                location_vmodel: "",
                same_as_permanent_address: false,
                is_checked: false
            },
            country_options: countries,
            maxlengthaccountzipcode: 30,
            maxlengthaccounttownship: 30,
            maxlengthaccountstreet: 30,
            states: [],
            isState: false,
            select_country: false,
            save_api_call_in_progress: false
        }
    },
    components: {
        SweetModal,
        HollowDotsSpinner
    },
    props: ["modal_name", 'add_edit_address_data', 'add_edit_mode'],
    mixins: [profile, User],
    methods: {
        hideAddEditAddress() {
            this.$emit('hideAddEditAddressPopup')
        },
        saveAddress() {
        this.addAddressMethod();
        },
        countryClose(data) {
            this.add_edit_address.country = data;
            this.$forceUpdate();
        },
        stateClose(data) {
            this.add_edit_address.state = data;
            this.$forceUpdate();
        },
        updateState(country) {
            this.isState = true;
            this.states = [];
            if (country) {
                let params = {
                country_required: false,
                state_required: true,
                country_name: country,
                };
                this.$http.post(globals.CRM_API +"/location/get_location", params).then(res => {
                if (res.data.status_id == 1 && "all_states" in res.data.response) {
                    res.data.response.all_states.forEach(x => {
                    this.states.push(x);
                    });
                    this.isState = false
                }
                });
            }
        },
        async addAddressMethod() {
            try {
                this.$validator.validateAll().then( async result => {
                    if(result) {
                        let params = {};
                        this.save_api_call_in_progress = true
                        if(this.add_edit_address.id) {
                            params.id = this.add_edit_address.id;
                            params.country = this.add_edit_address.country
                            params.state = this.add_edit_address.state
                            params.city = this.add_edit_address.city
                            params.township = this.add_edit_address.township
                            params.street = this.add_edit_address.street
                            params.zip_code = this.add_edit_address.zip_code
                            params.address_type = this.add_edit_address.address_type
                            let response = await this.updateAddress(params, this.loggedInUser.user_id);
                            if(response.status_id == 1) {
                                this.save_api_call_in_progress = false
                                this.success_msg = 'Address updated successfully.'
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.$emit('addressAddEditSuccess');
                                }, 3000);
                            }
                        } else {
                            params.country = this.add_edit_address.country
                            params.state = this.add_edit_address.state
                            params.city = this.add_edit_address.city
                            params.township = this.add_edit_address.township
                            params.street = this.add_edit_address.street
                            params.zip_code = this.add_edit_address.zip_code
                            params.address_type = this.add_edit_address.address_type
                            if(this.add_edit_address.is_primary) {
                                params.is_primary = true
                            } else {
                                params.is_primary = false
                            }
                            let response = await this.addNewAddress(params, this.loggedInUser.user_id);
                            if(response.status_id == 1) {
                                this.save_api_call_in_progress = false
                                this.success_msg = 'Address added successfully.'
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.$emit('addressAddEditSuccess');
                                }, 3000);
                            }
                        }
                    } else {
                        console.log('error')
                        this.save_api_call_in_progress = false
                    }
                })
            }
            catch(err) {
                console.log(err);
                this.warning_msg = err
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                    this.hideAddEditAddress();
                }, 3000);
                this.save_api_call_in_progress = false
            }
        },
        setPlace(place) {
            let currentPlace = place;
            let address = place.formatted_address.split(", ");
            this.add_edit_address.street =
                address[address.length - 3] || "";
            this.add_edit_address.township =
                address[address.length - 4] || "";
            this.add_edit_address.city = currentPlace.vicinity;
            let statezip = address[address.length - 2]
                ? address[address.length - 2].split(" ")
                : ["", ""];
            if (statezip.length == 1) {
                if (statezip[0].match(/^[0-9]+$/)) {
                this.add_edit_address.zip_code = statezip[0];
                } else {
                this.add_edit_address.state = statezip[0];
                }
            } else {
                this.add_edit_address.zip_code = statezip[0].match(/^[0-9]+$/)
                ? statezip[0]
                : statezip[1].match(/^[0-9]+$/)
                ? statezip[1]
                : "";
                this.add_edit_address.state = statezip[0].match(/^[a-zA-Z]+$/)
                ? statezip[0]
                : statezip[1].match(/^[a-zA-Z]+$/)
                ? statezip[1]
                : "";
            }
            if (address[address.length - 1].includes("-")) {
                let countrySelected = address[address.length - 1].split(" - ");
                countrySelected.forEach(x => {
                if (this.countries.includes(x)) {
                    this.add_edit_address.country = x;
                } else {
                    this.add_edit_address.country = "";
                }
                });
            } else {
                this.add_edit_address.country = address[address.length - 1];
            }
            // this.add_edit_address.address.latitude = currentPlace.geometry.location.lat() || "";
            // this.add_edit_address.address.longitude = currentPlace.geometry.location.lng() || "";
            this.add_edit_address.location_vmodel = place.formatted_address;
            this.$forceUpdate();
        },
        getLocation() {
            // this.addressIndex = index;
            if (navigator.geolocation) {
                // timeout at 60000 milliseconds (60 seconds)
                var options = {
                enableHighAccuracy: false,
                timeout: 30000, // milliseconds (30 seconds)
                maximumAge: 600000
                }; // milliseconds (10 minutes)
                navigator.geolocation.getCurrentPosition(
                this.showLocation,
                this.errorHandler,
                options
                );
            } else {
                alert("Sorry, browser does not support geolocation!");
            }
        },
        showLocation(position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
            let calllatlong = axios.create();
            delete calllatlong.defaults.headers.common['Authorization'];
            calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
            .then(response => {
                let address = response.data.results[0].formatted_address.split(", ");
                this.add_edit_address.street =
                address[address.length - 6] || "";
                this.add_edit_address.township =
                address[address.length - 4] || "";
                this.add_edit_address.city = address[address.length - 3] || "";
                let statezip = address[address.length - 2]
                ? address[address.length - 2].split(" ")
                : ["", ""];
                this.add_edit_address.zip_code = statezip[0].match(/^[0-9]+$/)
                ? statezip[0]
                : statezip[1].match(/^[0-9]+$/)
                ? statezip[1]
                : "";
                this.add_edit_address.state = statezip[0].match(/^[a-zA-Z]+$/)
                ? statezip[0]
                : statezip[1].match(/^[a-zA-Z]+$/)
                ? statezip[1]
                : "";
                if (address[address.length - 1].includes("-")) {
                let countrySelected = address[address.length - 1].split(" - ");
                countrySelected.forEach(x => {
                    if (this.countries.includes(x)) {
                    this.add_edit_address.country = x;
                    } else {
                    this.add_edit_address.country = "";
                    }
                });
                } else {
                this.add_edit_address.country = address[address.length - 1];
                }
                this.add_edit_address.location_vmodel = response.data.results[0].formatted_address;
                this.$forceUpdate();
            })
            .catch(e => {
                // this.errors.push(e)
            });
        },
        errorHandler(err) {
            if (err.code == 1) {
                // alert("Error: Access is denied!");
            } else if (err.code == 2) {
                // alert("Error: Position is unavailable!");
            }
        },
    },
    mounted() {
        this.add_edit_address = this.add_edit_address_data
    },
    watch: {
        
    },
    computed: {
        fields_not_changed(){
            return this.stringified_response == JSON.stringify(this.create_data)
        },
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        
    },
    created() {
        const dict = {
            custom: {
                country: {
                    required:() => "Country is required",
                },
                state: {
                    required:() => "State is required",
                },
            }
        }
        this.$validator.localize('en', dict)
    }
}
</script>
<style scoped>
    .multiselect.diginew-multiselect .multiselect__select{
        top: 4px !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>